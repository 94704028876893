import React from "react"
import LogoCmpc from '../images/logo-cmpc.inline.svg'
import LogoDdc from '../images/logo-ddc.inline.svg'
import LogoMincap from '../images/logo-mincap.inline.svg'
import { StaticImage } from 'gatsby-plugin-image'

import { Trans } from 'gatsby-plugin-react-i18next'


const SectPartners = () => {
  return (
    <section className='partners bg-beige'>
      <div className='container flex flex-col items-center mx-auto divide-y lg:divide-y-0 lg:justify-around lg:space-y-0 lg:grid lg:grid-cols-2'>
        <div className='flex flex-col items-center w-full px-4 py-16 lg:border-r lg:flex-row lg:space-x-6'>
          <h2 className='self-start text-xs text-left underline uppercase lg:self-center lg:mb-4 lg:mr-24 lg:text-sm lg:mx-0'>
            <Trans>Organiza</Trans>
          </h2>

          <div className='flex flex-col items-center justify-center'>
            <LogoCmpc className='w-40 text-black fill-current' />
          </div>
        </div>
        <div className='flex flex-col items-center justify-center w-full px-4 py-8 lg:items-center lg:flex-row lg:space-x-10'>
          <h2 className='self-start text-xs text-left underline uppercase lg:self-center lg:mb-4 lg:mr-4lg:text-sm lg:mx-0'>
            <Trans>Colaboran</Trans>
          </h2>
          <div className='flex items-center justify-center space-x-8'>
            <LogoDdc className='flex-none h-16 mt-auto ' />
            <LogoMincap className='flex-none h-20' />
          </div>
        </div>
      </div>

      <hr />

      <div className='container flex flex-col px-4 py-16 mx-auto lg:flex-row'>
        <h2 className='self-start text-xs text-left underline uppercase lg:self-center lg:mb-4 lg:mr-20 lg:text-sm lg:mx-0'>
          Apoyan
        </h2>
        <div className='flex flex-col items-center justify-between w-full space-y-8 lg:space-y-0 lg:flex-row'>
          {/* <StaticImage
            layout='fixed'
            height={48}
            src='../images/logo-bid.png'
            alt='BID'
            className='flex-none '
          /> */}
          <StaticImage
            layout='fixed'
            height={100}
            src='../images/logo-3c.png'
            alt='3 triciclos'
            className='flex-none '
          />
          <StaticImage
            layout='fixed'
            height={31}
            src='../images/logo-parsons.png'
            alt='Parsons'
            className='flex-none '
          />
          {/* <StaticImage
            layout='fixed'
            height={65}
            src='../images/logo-rca.png'
            alt='RCA'
            className='flex-none '
          /> */}
          <StaticImage
            layout='fixed'
            height={100}
            src='../images/logo-incuba.png'
            alt='Incuba UdeC'
            className='flex-none '
          />
          <StaticImage
            layout='fixed'
            height={94}
            src='../images/logo-amee.png'
            alt='AMEE'
            className='flex-none '
          />
        </div>
      </div>
    </section>
  )
}

export default SectPartners