import React, { useRef, useEffect, useState } from "react"
import ReactPlayer from 'react-player'
import {gsap} from "gsap"
// import {Link} from "gatsby"

import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next'
const SectAboutPrograma = () => {


  const { t } = useTranslation()

  const [isReady, setIsReady] = useState(false)

  const playerRef = useRef()

  useEffect(() => {
    isReady &&
      playerRef.current &&
      gsap.to(playerRef.current, {autoAlpha: 1, delay: 0.5})
  },[isReady])

  return (
    <section className='flex py-16 mx-auto xl:container about-programa lg:py-28'>
      <div className='w-full'>
        <h2 className='px-4 mb-8 text-sm underline uppercase w-60 lg:w-auto lg:text-lg font-regular'>
          <Trans>Programa de Packaging Sostenible</Trans>
        </h2>

        <p className='max-w-4xl px-4 mb-12 text-3xl font-extrabold text-white lg:mb-0 lg:text-5xl'>
          <Trans>
            La crisis climática es uno de los grandes retos que enfrenta hoy el
            planeta y requiere un cambio profundo en la manera en que la
            sociedad produce bienes y servicios. En CMPC nos preguntamos cómo
            podemos contribuir a abordar estos desafíos y por eso creamos SAVIA.
          </Trans>
        </p>

        <div className='flex flex-col grid-cols-3 space-y-10 lg:space-y-0 gap-x-32 lg:grid lg:px-4'>
          <div className='lg:pt-28'>
            <Link
              className='block px-6 py-3 text-2xl font-semibold text-center text-black uppercase border rounded-full hover:bg-black hover:text-green '
              to='/cmpc'
            >
              <Trans>Sobre CMPC</Trans>
            </Link>
          </div>
          <div
            ref={playerRef}
            className='w-full col-span-2 transition-opacity duration-700 delay-100 opacity-0 transform-gpu player-wrapper'
          >
            <ReactPlayer
              url={t('https://vimeo.com/554747033')}
              controls={true}
              className=' react-player'
              width='100%'
              height='100%'
              onReady={() => {
                setIsReady(true)
              }}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectAboutPrograma