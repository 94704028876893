import React from "react"
import { Trans } from 'gatsby-plugin-react-i18next'
import { Link } from 'gatsby-plugin-react-i18next'


const SectPostula = () => {
  return (
    <section className='flex w-full px-4 border-t postula py16 lg:py-28'>
      <div className='flex flex-col w-full max-w-4xl py-16 mx-auto leading-normal text-left lg:py-0'>
        <h2 className='mb-8 underline uppercase lg:text-lg'>
          <Trans>¿A quiénes buscamos?</Trans>
        </h2>

        <p className='mb-16 text-2xl font-extrabold leading-tight lg:mb-20 lg:text-5xl'>
          <Trans i18nKey='postulaTextLong'>
            Buscamos <span className='underline'>equipos de todo el mundo</span>{' '}
            que tengan soluciones listas para implementar o en proceso final de
            desarrollo, con alto potencial de escalabilidad y/o
            comercialización.
          </Trans>
        </p>

        <div className='flex flex-col items-end justify-between lg:flex-row'>
          <p className='mb-16 lg:mb-0'>
            <Trans>
              En SAVIA pueden postular empresas, emprendimientos, instituciones,
              desarrolladores, diseñadores, etc...
            </Trans>
          </p>

          <Link
            className='flex items-center justify-center w-full px-6 py-3 text-2xl font-semibold text-white uppercase border-none rounded-full lg:max-w-xs button-noticias bg-orange'
            to='/about/'
          >
            <Trans>Conoce más</Trans>
          </Link>
        </div>
      </div>
    </section>
  )
}

export default SectPostula