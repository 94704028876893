import React from "react"
import { StaticImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'


import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"
// import Marquee from "../components/marquee3k"


//sect-about-programa
import SectAboutPrograma from "../components/section-about-programa"

//sect-about-savia
import SectAboutSavia from "../components/section-about-savia"

//sect-desafios
import SectDesafios from "../components/section-desafios"
import SectPostula from '../components/section-postula'

//sect-etapas
import SectEtapas from "../components/section-etapas"
import SectJurado from '../components/section-jurado'
import SectPorQue from '../components/section-por-que'

import SectNoticias from "../components/section-noticias"
import SectPartners from '../components/section-partners'



const IndexPage = (props) => {

  return (
    <Layout isHomePage location={props.location}>
      <Seo title='Inicio' />
      <Hero></Hero>

      {/* <Marquee id='m1' /> */}
      <div className='grid bg-green'>
        <StaticImage
          style={{
            gridArea: '1/1',
            // You can set a maximum height for the image, if you wish.
            // maxHeight: 600,
          }}
          layout='fullWidth'
          // You can optionally force an aspect ratio for the generated image
          // aspectRatio={3 / 1}
          // This is a presentational image, so the alt should be an empty string
          alt=''
          // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash
          src={'../images/bg-savia.png'}
          formats={['auto', 'webp', 'avif']}
        />
        <div
          className='relative grid place-items-center'
          style={{ gridArea: '1/1' }}
        >
          <SectAboutPrograma />
          <SectAboutSavia />
          <SectPostula />
        </div>
      </div>
      <SectPorQue />

      <SectDesafios />

      <div className='bg-gradient-to-b from-beige to-green'>
        <SectEtapas />
        <SectJurado />
      </div>

      <SectNoticias />
      <SectPartners />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`