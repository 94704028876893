import React from "react"
import SaviaLogo from "../components/savia-logo"
// import { Link } from "gatsby"

import { Trans } from 'gatsby-plugin-react-i18next'
import { Link } from 'gatsby-plugin-react-i18next'

const SectAboutSavia = () => {
  return (
    <section className='flex px-4 pb-16 about-savia lg:pb-28'>
      <div className='flex flex-col items-center max-w-4xl mx-auto leading-normal text-center'>
        <h2 className='mb-8 text-lg font-semibold underline uppercase'>
          <SaviaLogo className='w-full max-w-xs text-black fill-current' />
        </h2>

        <p className='mb-16 text-xl font-bold leading-normal lg:mb-20 lg:text-3xl'>
          <Trans i18nKey='aboutTextLong'>
            Savia es un programa global de innovación que <strong className='bg-beige'>busca soluciones de packaging sostenible</strong> con alto potencial de escalabilidad, que generen valor en el consumidor y la sociedad en general, y que contribuyan a superar desafíos globales que son relevantes para el futuro de la industria y la crisis climática.
          </Trans>
        </p>

        <Link
          className='block w-full max-w-xs px-6 py-3 text-2xl font-semibold text-center text-white uppercase rounded-full bg-orange hover:bg-black hover:text-green '
          to='/about'
          text='Conoce más'
        >
          <Trans>Conoce más</Trans>
        </Link>
      </div>
    </section>
  )
}

export default SectAboutSavia