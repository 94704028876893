import React, { useState } from "react"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'
import SaviaLogo from "../components/savia-logo"
import Modal from "react-modal"

import {
  Trans,
  useI18next,
} from 'gatsby-plugin-react-i18next'



Modal.setAppElement('#___gatsby')

const Card = ({post, lang, index}) => {
  const [modalOpen, setModalOpen] = useState(false)

  const openModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  let cargo = post.infoJurado.cargo
  let descripcion = post.infoJurado.descripcion
  let title = post.title

  post.translations.forEach(trans => {
    // console.log(trans)
    if(lang.toUpperCase() === trans.language.code) {
      cargo = trans.infoJurado.cargo
      descripcion = trans.infoJurado.descripcion
    }
  });


  return (
    <>
      <button
        className='flex flex-col text-left cursor-pointer focus:outline-none'
        key={post.id}
        onClick={() => openModal()}
      >
        <GatsbyImage
          className='mb-1'
          placeholder='blurred'
          layout='fixed'
          image={getImage(post.featuredImage.node.localFile)}
          alt=""

          // className='mb-2'
        />

        <h3 className='mb-1 text-lg font-bold leading-tight'>{title}</h3>
        <div
          className='text-sm'
          dangerouslySetInnerHTML={{ __html: cargo }}
        />
      </button>
      <Modal
        className='flex flex-col w-screen h-screen max-w-3xl overflow-y-scroll lg:justify-center ml:pt-0 bg-none'
        overlayClassName='fixed flex flex-col items-center focus:outline-none justify-center z-10 inset-0 w-screen h-screen bg-black text-green'
        closeTimeoutMS={300}
        isOpen={modalOpen}
        onRequestClose={() => closeModal()}
      >
        <div className='relative flex flex-col w-full p-4 mx-auto max-w-screen lg:flex-row'>
          <button
            onClick={() => closeModal()}
            className='absolute right-0 top-4 lg:-top-8 focus:outline-none text-green'
          >
            <svg

              className='w-12 h-12 fill-current text-green'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path d='M18.8364 16.5L33 30.6636L30.6636 33L16.5 18.8364L2.33643 33L0 30.6636L14.1636 16.5L0 2.33643L2.33643 0L16.5 14.1636L30.6636 0L33 2.33643L18.8364 16.5Z' />
            </svg>
          </button>
          <div className='lg:flex-none lg:mr-10 lg:w-60 details__info text-green'>
            <GatsbyImage
              className='mt-10 mb-1 lg:mt-0'
              layout='fixed'
              placeholder='blurred'
              image={getImage(post.featuredImage.node.localFile)}
              alt=""

              // className='mb-2'
            />
            <h3 className='mb-4 text-lg font-bold'>{title}</h3>
            <div
              className='mb-4 text-sm'
              dangerouslySetInnerHTML={{ __html: cargo }}
            />
          </div>

          <div
            className='text-sm text-white lg:text-base details__description'
            dangerouslySetInnerHTML={{
              __html: descripcion
            }}
          ></div>
        </div>
        <SaviaLogo className='p-4 mx-auto mt-8 mb-4 fill-current lg:mt-20 w-28 text-green' />
      </Modal>
    </>
  )
}


const SectJurado = () => {
  const { language } = useI18next()



  const data = useStaticQuery(graphql`
    {
      allWpJurado(
        sort: { order: ASC, fields: menuOrder }
        filter: { language: { code: { eq: ES } } }
      ) {
        nodes {
          uri
          # date(formatString: "DD MMMM, YYYY")
          title
          id
          infoJurado {
            cargo
            descripcion
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    aspectRatio: 1
                    width: 250
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
          language {
            code # Language code
          }
          translations {
            language {
              code
              locale
            }
            infoJurado {
              cargo
              descripcion
            }
          }
        }
      }
    }
  `)

  const posts = data.allWpJurado.nodes
  return (
    <>
      <section className='px-4 py-16 border-t jurado'>
        <h2 className='mb-4 text-center underline uppercase lg:text-lg'>
          <Trans>Conoce al jurado</Trans>
        </h2>
        <h3 className='mb-16 text-4xl font-extrabold text-center'>
          <Trans>Jurado Experto Savia</Trans>
        </h3>
        <div className='container grid grid-cols-2 gap-6 mx-auto mb-16 lg:grid-cols-5'>
          {posts.map((post, index) => {
            return <Card post={post} index={index} lang={language} key={post.id}></Card>
          })}
        </div>

        {/* <a
          className='flex items-center justify-center w-full px-6 py-3 mx-auto mb-10 text-2xl font-semibold text-white uppercase border-none rounded-full lg:max-w-xs button-noticias bg-orange'
          href='#/'
        >
          Postula
        </a> */}
      </section>
    </>
  )
}

export default SectJurado