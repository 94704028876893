import React from "react"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'

import { Link, Trans } from 'gatsby-plugin-react-i18next'

const SectNoticias = () => {

  const data = useStaticQuery(graphql`
    {
      allWpPost(
        limit: 4
        sort: { fields: [date], order: DESC } # limit: $postsPerPage # skip: $offset
      ) {
        nodes {
          uri
          date(formatString: "DD.MM.YYYY")
          title
          id
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    aspectRatio: 2
                    width: 500
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
    }
  `)

  const posts = data.allWpPost.nodes

  return (
    <section className='border-b noticias py-28 bg-beige'>
      <div className='flex flex-col px-4 mx-auto mb-8 lg:mb-16 xl:container'>
        <h2 className='mb-8 text-sm underline uppercase lg:text-lg'>
          <Trans>Noticias</Trans>
        </h2>
        <div className='flex flex-col lg:flex-row lg:items-end lg:justify-between'>
          <h3 className='mb-4 text-2xl font-bold lg:mb-0 lg:max-w-xl lg:text-4xl'>
            <Trans>Conoce las últimas novedades de Savia</Trans>
          </h3>

          <Link
            className='items-center justify-center hidden w-full py-3 ml-auto text-2xl font-semibold text-white uppercase border-none rounded-full lg:flex px-11 lg:max-w-sm button-noticias bg-orange'
            to='/noticias/'
          >
            <Trans>Ver más noticias</Trans>
          </Link>
        </div>
      </div>

      <div className='pr-4 mx-auto mb-10 overflow-x-scroll lg:mb-0 xl:container xl:overflow-hidden'>
        <div className='flex min-w-full grid-cols-5 px-4 mx-auto space-x-8 xl:space-x-0 xl:gap-8 xl:grid noticias__wrapper'>
          {posts.map((post, index) => {
            return (
              <Link
                to={post.uri}
                key={post.id}
                className={`noticias__item ${
                  index === 0 ? 'noticias__item--lg' : ''
                }`}
              >
                {post.featuredImage.node && (
                  <GatsbyImage
                    className='w-full h-56 mb-4'
                    image={getImage(post.featuredImage.node.localFile)}
                    alt=''
                  />
                )}

                <h3 className='mb-4'>{post.title}</h3>

                <time className='py-2 mt-auto text-base font-bold border-b-4 border-green w-max'>
                  {post.date}
                </time>
              </Link>
            )
          })}

          <span className='flex-none w-4 -ml-4 spacer'></span>
        </div>
      </div>

      <div className='container px-4 mx-auto'>
        <Link
          className='flex items-center justify-center w-full py-3 ml-auto text-2xl font-semibold text-white uppercase border-none rounded-full lg:hidden px-11 lg:max-w-sm button-noticias bg-orange'
          to='/noticias/'
        >
          Ver más noticias
        </Link>
      </div>
    </section>
  )
}

export default SectNoticias