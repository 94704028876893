import React, { useRef, useState, useEffect } from "react"
import { StaticImage } from "gatsby-plugin-image"
import SaviaLogo from '../components/savia-logo'
import LogoCmpc from '../images/logo-cmpc.inline.svg'
import LogoDdc from '../images/logo-ddc.inline.svg'
import LogoMincap from '../images/logo-mincap.inline.svg'
import { Trans, useI18next, Link } from 'gatsby-plugin-react-i18next'
import Arrow from '../images/arrow-alt.inline.svg'

import { gsap } from 'gsap'





const Hero = () => {
  const { languages, originalPath, language } = useI18next()
  const heroRef = useRef()

  const trackDownload = () => {
    typeof window !== 'undefined' &&
      window.gtag('event', 'click', {
        event_category: 'download',
        event_label: 'download-bases',
      })
  }

   const [isReady, setIsReady] = useState(false)

   const playerRef = useRef()

   useEffect(() => {
     isReady &&
       playerRef.current &&
       gsap.to(playerRef.current, { autoAlpha: 1, delay: 0.5 })
   }, [isReady])

   const playerConfig = {
     vimeo: {
       autoplay: true,
       loop: true,
       muted: true,
     },
   }

  return (
    <>
      <section
        ref={heroRef}
        className='relative flex flex-col justify-between pt-8 pb-16 lg:pt-0 bg-beige'
      >
        {/* <div className='h-2 lg:h-12 spacer'></div> */}
        {/* <div className='py-2 text-center uppercase bg-black text-green'>
          <a
            href='https://www.eventbrite.cl/e/registro-savia-packaging-2021-159622839209'
            target='_blank'

            className='flex justify-center mx-auto text-center underline bold'
          >
            <Link to='/evento'>
              <Trans>Revive el evento de lanzamiento de SAVIA</Trans>
            </Link>
            <span className='ml-2'>↗</span>
          </a>
        </div> */}
        <div className='container relative items-center justify-center px-4 mx-auto'>
          {/* <div className='pt-4 lg:p-4 hero__sub-head lg:w-60'>
            <p>
              <Trans>Programa de Packaging Sostenible</Trans>
            </p>
          </div> */}
          <div className='invisible max-w-sm pt-4 text-xl lg:px-4 hero__sub-head'>
            <p>
              <span className='flex items-center justify-center w-6 h-6 mb-2 bg-black rounded-full text-beige'>
                !
              </span>
              <Trans i18nKey='launchDate'>
                Inscríbete en los webinar de SAVIA
              </Trans>

              <a
                href='https://www.eventbrite.cl/o/savia-packaging-33680415037'
                target='_blank'
                rel='noreferrer'
                className='block underline uppercase bold'
              >
                <Trans>¡Regístrate aquí!</Trans>
              </a>
            </p>
          </div>
          <div className='relative flex flex-col items-end justify-center max-w-xl mx-auto mb-10 pt-14 mb lg:mb-20 hero__comp'>
            <div className='absolute inset-0 z-0 mt-10 ml-12 lg:mt-0 w-52 lg:w-auto'>
              <StaticImage
                placeholder='none'
                width={384}
                src='../images/blob-static.png'
                alt=''
              />
            </div>
            <p className='relative mb-4 mr-8 text-xs text-right w-28 lg:w-56 lg:text-2xl'>
              <Trans>Imaginando el futuro de nuestras fibras</Trans>
            </p>
            <SaviaLogo className='relative w-full max-w-xl fill-current text-green' />
          </div>
          {/* <div className='max-w-sm pt-4 text-xl lg:px-4 hero__sub-head'>
            <p>
              <span className='flex items-center justify-center w-6 h-6 bg-black rounded-full text-beige'>
                !
              </span>
              <Trans i18nKey='launchDate'>
                Evento de Lanzamiento
                <span className='block uppercase'>
                  29 de junio a las 12:00pm ET
                </span>
              </Trans>

              <Link to='/evento' className='block underline bold'>
                <Trans>¡Revívelo aquí!</Trans>
              </Link>
            </p>
          </div> */}
        </div>

        <div className='hidden px-4 mx-auto lg:block xl:container'>
          <a
            onClick={trackDownload}
            className='block py-2 text-center uppercase border rounded-full lg:ml-auto w-52 hover:bg-black hover:text-green '
            href={`/assets/bases-${language}.pdf`}
            download
          >
            <Trans>Descarga las bases</Trans>
          </a>
        </div>
      </section>
      <aside className='border-t bg-beige'>
        <div className='container grid items-center mx-auto divide-y divide-black lg:divide-y-0 lg:divide-x lg:grid-cols-3'>
          <div className='flex items-center justify-center h-full px-4 py-4 text-2xl italic font-bold text-center lg:text-3xl'>
            <p className='mr-6 lg:mr-10'>
              <div className="relative">
                <Trans>29 junio - 31 agosto</Trans>
                <span className='absolute right-0 text-sm font-semibold underline -bottom-6'>9:00AM ET</span>
              </div>
            </p>{' '}
            <Arrow className='w-10 lg:w-16 ' />
          </div>
          <div className='flex flex-col items-center justify-center h-full px-4 py-4 text-center'>
            <a
              target='_blank'
              href='https:///app.saviapackaging.com/es/guia/'
              className='block w-full max-w-xs py-3 mx-auto text-2xl font-semibold text-center text-black uppercase rounded-full bg-green hover:bg-black hover:text-green'
            >
              <Trans>Postula</Trans>
            </a>
          </div>

          <div className='flex justify-between h-full px-4 py-4 space-x-8 lg:pb-8'>
            <div className='flex flex-col '>
              <h2 className='mb-0 text-xs text-left uppercase lg:text-sm'>
                <Trans>Organiza</Trans>
              </h2>

              <div className=''>
                <LogoCmpc className='ml-0 text-black fill-current lg:w-44 w-36' />
              </div>
            </div>
            <div className='flex flex-col'>
              <h2 className='mb-4 text-xs uppercase lg:text-sm '>
                <Trans>Colaboran</Trans>
              </h2>
              <div className='flex items-end justify-center mt-auto space-x-4 space-between h-fill'>
                <LogoDdc className='h-8 mt-auto lg:h-10 ' />
                <LogoMincap className='h-10 ' />
              </div>
            </div>
          </div>
          <div className='flex flex-col items-center justify-center h-full px-4 py-4 text-center lg:hidden'>
            <a
              download
              onClick={trackDownload}
              className='block w-full max-w-xs py-3 mx-auto text-2xl font-semibold text-center text-black uppercase border rounded-full hover:bg-black hover:text-green '
              href={`/assets/bases-${language}.pdf`}
            >
              <Trans>Descarga las bases</Trans>
            </a>
          </div>
        </div>
      </aside>
    </>
  )
}

export default Hero
